import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Hero = ({ image, title, description = '', alt = '' }) => {
  const props = alt ? { alt: alt } : { role: 'presentation' }
  return (
    <section id="banner">
      <div className="inner">
        <header>
          <h2 className="hs h2">{title}</h2>
        </header>
        <GatsbyImage
          className="banner-image"
          // css={{ top: 0, left: 0, right: 0, bottom: 0 }}
          image={image}
          {...props}
          loading="eager"
        />
        <p className="p">{description}</p>
      </div>
    </section>
  )
}

export default Hero
