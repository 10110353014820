import React from 'react'

const Talks = () => {
  return (
    <section id="speaking" className="wrapper style4 container">
      <div className="talks">
        <h2 className="hs h2">Current Talks</h2>
        <h3 className="hs h3">Behind the Gatsby Magic</h3>
        <p className="p">
        Ever wondered how the Imagineers built Space Mountain? How about Spaceship Earth? Every day designers and engineers create amazing, magical experiences for park-goers to enjoy!
        <br/>
        Developers are doing the same thing with Gatsby! Creating incredibly fast and performant sites right out of the box. But just like Disney, it's powered by a lot of magic behind the scenes. Let's dive in and learn how it works.
        </p>
        <h3 className="hs h3">Keynote: How to Talk Like an Engineer</h3>
        <p className="p">
          It is often said that the hardest problem to solve is when two people
          are talking about different things but believe they are talking about
          the same thing. As you grow in your engineering career being able
          communicate about problems effectively is perhaps your single most
          important skill. This talk is about adding to a dictionary of common
          vocabulary. I'm here to arm you with the confidence and drive to
          revisit your technical communication skills and improve them.
        </p>
        <h3 className="hs h3">Human Readable JavaScript: Using ES2015+ to Craft Better Code</h3>
        <p className="p">
          Love JavaScript? Awesome! Hate JavaScript? That's ok! We know that
          JavaScript hasn't been very easy to read in the past, lots of dollar
          signs and slick one-liners.
          <br />
          The good news is that things have gotten better! In this talk we'll
          look at some of the syntax ES2015+ introduced to help us write more
          readable code for our coworkers and our future selves.
        </p>
        <h3 className="hs h3">Keynote: A Software Engineer's Guide to DevOps</h3>
        <p className="p">
          DevOps is meant to bring together developers and the operations team,
          but we all know that it often means rebranding Ops as DevOps. In order
          to truly bridge the gap between these teams, developers need to
          understand what DevOps is and how it impacts their work.
          <br />
          After a stint in the DevOps deep end, I came away with a better
          perspective of how much DevOps should matter to me as a coder. And
          what entry points developers at large can use to make the technologies
          and concepts of DevOps more accessible.
        </p>
        <h3 className="hs h3">What Happened to My Promises? A Primer on RxJS</h3>
        <p className="p">
          If you're familiar with AngularJS you may be used to interacting with
          your Http requests via promises. Well, no more. With Angular promises
          have given way to observables. Specifically, Angular's implementation
          is built on top of RxJS. So let's learn about this reactive
          programming library, what it is, and how we can use it in our
          applications. And be prepared, we can do a lot more than .then now!
        </p>
        <h3 className="hs h3">A Journey With Vue-Router</h3>
        <p className="p">
          Routing is an amazing and powerful tool that exists in most modern
          JavaScript projects. It happens to be particularly well thought out in
          Vue! From navigation guards, to sub routes and even wildcard paths,
          there is so much to explore. Let's live code a project with vue-router
          and discover what it can do!
        </p>
        <h3 className="hs h3">How to Talk Like an Engineer</h3>
        <p className="p">
          It is often said that the hardest problem to solve is when two people
          are talking about different things but believe they are talking about
          the same thing. As you grow in your engineering career being able
          communicate about problems effectively is perhaps your single most
          important skill. This talk is about adding to a dictionary of common
          vocabulary. I'm here to arm you with the confidence and drive to
          revisit your technical communication skills and improve them.
        </p>
        <h3 className="hs h3">A Software Engineer's Guide to DevOps</h3>
        <p className="p">
          I'm a software engineer who spends her time writing code and
          developing apps. I have a pretty good grasp of the vocabulary and
          technologies relevant to my job. But what happens when another facet
          of engineering, one that is gaining a lot of traction and has a large
          footprint of its own starts becoming more and more relevant to my day
          to day tasks? Well, that's exactly what happened to me last year when
          DevOps became a big part of my role. This is my attempt to impart all
          of that knowledge onto you.
        </p>
        <h3 className="hs h3">The Forever UI</h3>
        <p className="p">
          How do you build a tool that can live forever? A tool that can evolve
          along with updates, and new hardware and ever changing technical
          specs? This talk is about a tool that does just that. What it does.
          How it works. And how we built it.
        </p>
        <h3 className="hs h3">Building Tech for the Non-Coder</h3>
        <p className="p">
          Everyone has blindspots. For developers it is often taking for granted
          certain technical skill sets. So what happens when you need to build a
          system for people without those skills? How do you adjust? What do you
          use as a reference point for common knowledge? How do you get out of
          your own head?
        </p>
        <h3 className="hs h3">
          The Tablecloth Trick: Legacy Modernization Without User Process
          Disruption
        </h3>
        <p className="p">
          Everyone has encountered a system that just had to be upgraded. But
          often that happens when users are used to the system and aren’t
          looking for a change. This is a series of stories for how companies
          have tried and failed and learned along the way. You’ll come away with
          some new pitfalls to avoid.
        </p>
      </div>
    </section>
  )
}

export default Talks
