import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Event = ({ event }) => {
  return (
    <div key={event.date} className="conference">
      <h3 className="date hs h3">{event.date}</h3>
      <GatsbyImage
        image={event.image.childImageSharp.gatsbyImageData}
        role="presentation"
      />
      <br />
      <a
        className="conf a"
        target="_blank"
        rel="noopener noreferrer"
        href={event.url}
      >
        <h4 className="conf hs"> {event.conference}</h4>
      </a>
      <p className="location p">{event.location}</p>
      <ul className="no-bullets ul">
        {event.talks.map(({ title, video, type }) => (
          <li key={title} className="talk">
            <p className="p">{title}</p>
            <a
              className={video ? 'button a' : 'emptyvideo a'}
              target="_blank"
              rel="noopener noreferrer"
              href={video}
              aria-label={`Listen to ${title}`}
            >
              {type}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Event
